<template>
  <van-popup v-model="visible" lock-scroll>
    <div class="notice">
      <!--      <van-cell>-->
      <!--        <template #title>-->
      <!--          <div class="blue-box"></div>-->
      <!--          <span class="custom-title">详细信息</span>-->
      <!--          <van-icon name="cross" @click="close"/>-->
      <!--        </template>-->
      <!--      </van-cell>-->
      <div class="table">
        <van-cell>
          <div class="top">
            <span>序号</span>
            <span>答案</span>
            <van-icon name="cross" @click="close"/>
          </div>
        </van-cell>
        <div class="bottom">
          <van-list  v-model="loading" :finished="finished" finished-text="没有更多了" @load="getInfo" v-if="dataList.length > 0" offset="10">
            <van-cell v-for="(item,index) in dataList" :key="index">
              <van-cell-group >
                <van-cell class="title" center>
                  <van-row>
                    <van-col :span="9">{{index + 1}}</van-col>
                    <van-col :span="15" >{{item}}</van-col>
                  </van-row>
                </van-cell>
              </van-cell-group>
            </van-cell>
          </van-list>
          <van-empty description="没有数据哦"  v-if="dataList.length < 1"/>
        </div>
      </div>
    </div>
  </van-popup>
</template>

<script>
export default {
  components :{
  },
  data() {
    return {
      loading: false,
      finished: false,
      visible: false,
      page: 0,
      id: 0,
      dataList: []
    };
  },
  created() {
    this.$toast.loading({
      duration: 0,
      message: '加载中...',
      forbidClick: true,
    });
    this.getInfo()
  },
  methods: {
    init(id) {
      this.id = id || 0
      this.visible = true
      this.getInfo(id)
    },
    close() {
      this.visible = false
    },
    getInfo() {
      this.$toast.loading({duration: 0, message: '加载中...', forbidClick: true,});
      this.$httpApp({
        url: this.$httpApp.adornUrl('/wxapp/questionnaire/statistics'),
        method: 'post',
        params: this.$httpApp.adornParams({
          id: this.id,
          orgId: this.$orgId,
        },false)
      }).then(({data}) => {
        if (data.code == 0) {
          data.list.map(item => {
            if (item.type === '0') {
              this.dataList = item.statistics
              if (this.dataList.length == item.statistics.length) {
                this.finished = true
              }
            }
          })
          // 加载状态结束
          this.loading = false;
          this.$toast.clear()
        }
        this.$toast.clear()
      }, err => {
        this.$toast.clear()
      })
    },
  }
}
</script>
<style>

</style>
<style lang="scss" scoped>
.notice .van-field__control{
  border: 2px solid #eee;
  border-radius: 10px;
  padding-left: 15px;
  padding-top: 5px;
}
.van-icon-cross::before {
  position: absolute;
  top: -20px;
  left: 510px;
  font-size: 28px;
}
.van-col {
  //height: 80px;
  line-height: 80px;
  text-align: center;
}
.van-col--9 {
  width: 35.5%;
}
.van-cell__value--alone {
  font-size: 30px;
  color: #929292;
}
.notice {
  background-color: #fff;
  p {
    margin-left: 15px;
    color: #929292;
  }
}
.van-popup {
  width: 99%;
  height: 50%;
  top: 40%;
}
.table {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  //border: 1px solid #ccc;
  .van-cell {
    padding: 0;
    border-bottom: 1px solid #F3F9FB;
  }
  .top {
    //display: flex;
    position: relative;
    width: 100%;
    height: 100px;
    line-height: 100px;
    font-size: 32px;
    color: #528BF6;
    background-color: #DEEFFF;
    .van-icon {
      position: absolute;
      top: 40px;
      right: 520px;
      width: 50px;
      height: 50px;
      color: #000;
    }
    span:nth-child(1){
      width: 250px;
      margin-left: 100px;
      //border-right: 1px solid #F3F9FB;
    }
    span:nth-child(2){
      margin-left: 300px;
    }
  }
  .bottom {
    .van-col:nth-child(1) {
      border-right: 1px solid #F3F9FB;
    }
  }
}

</style>
